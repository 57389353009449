import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyDesignOverview, StudyDesignArms, StudyCopyBlock } from '../index';

const CHL = () => (
    
    <ComponentWrapper>
        <StudyDesignOverview title="Study Overview" description="A Phase 3, Randomized, Open-Label, Active-Controlled Study of KEYTRUDA vs Brentuximab Vedotin<sup>1</sup>" data={[
            [
                {
                    title: 'Patient Population',
                    description: `
                        <p>304 adult patients with R/R cHL after at least 1 multi-agent chemotherapy regimen</p>
                        <p>Key Ineligibility Criteria:<br /> Active, noninfectious pneumonitis; prior pneumonitis requiring steroids; active autoimmune disease; medical condition requiring immunosuppression; received an allogeneic HSCT within the past 5 years</p>
                    `,
                    type: 'box-gradient',
                    colspan: 2
                }
            ],
            [
                {
                    type: 'arrow-gray',
                    colspan: 2
                }
            ],
            [
                {
                    title: 'Randomized<sup>e</sup> (1:1) (N=304)',
                    colspan: 2
                }
            ],
            [
                {
                    type: 'arrow-gray'
                },
                {
                    type: 'arrow-gray'
                }
            ],
            // [
            //     {
            //         title: 'Treatment',
            //         type: 'box-green box-small-title'
            //     },
            //     {
            //         title: 'Treatment',
            //         type: 'box-small-title'
            //     }
            // ],
            // [
            //     {
            //         type: 'arrow-green'
            //     },
            //     {
            //         type: 'arrow-gray'
            //     }
            // ],
            // [
            //     {
            //         title: 'Treatment Duration',
            //         type: 'box-small-title'
            //     },
            //     {
            //         title: 'Treatment Duration',
            //         type: 'box-small-title'
            //     }
            // ]
        ]} />
    
        <StudyDesignArms title="Study Arms" arms={[
            {
                title: "KEYTRUDA",
                data: [
                    [
                        {
                            title: 'Treatment',
                            description: `KEYTRUDA 200&nbsp;mg IV every 3 weeks (Q3W) (n=151)`,
                            type: 'box-green'
                        }
                    ],
                    [
                        {
                            type: 'arrow-green'
                        }
                    ],
                    [
                        {
                            title: 'Treatment Duration',
                            description: `Unacceptable toxicity or documented disease progression, or a maximum of 35 cycles (up to approximately 2 years). Disease assessment performed every 12 weeks.`
                        }
                    ]
                ]
            },
            {
                title: "Brentuximab Vedotin",
                data: [
                    [
                        {
                            title: 'Treatment',
                            description: `Brentuximab Vedotin 1.8&nbsp;mg/kg IV Q3W (n=153)`,
                        }
                    ],
                    [
                        {
                            type: 'arrow-gray'
                        }
                    ],
                    [
                        {
                            title: 'Treatment Duration',
                            description: `Unacceptable toxicity or documented disease progression, or a maximum of 35 cycles (up to approximately 2 years). Disease assessment performed every 12 weeks.`
                        }
                    ]
                ]
            }
        ]} />
    
        <StudyCopyBlock 
            title="End Points"
            description={`
                <p>Main Efficacy Measure: PFS assessed by blinded independent central review (BICR) using 2007 revised International Working Group criteria.</p>
            `} 
        />
    
        <StudyCopyBlock 
            title="Patient Characteristics:"
            description={`
                <ul>
                    <li>Median age: 35 years (range: 18–84 years)</li>
                    <li>57% male</li>
                    <li>77% White</li>
                    <li>9% Asian</li>
                    <li>3.9% Black</li>
                </ul>
            `} 
        />
    
        <StudyCopyBlock 
            title="Treatment History:"
            description={`
                <ul>
                    <li>18% received at least one previous line of therapy</li>
                    <li>Median number of prior therapies with KEYTRUDA: 2 (range: 1–10)</li>
                    <li>Median number of prior therapies with BV: 3 (range: 1–11)</li>
                    <li>42% refractory to last prior therapy</li>
                    <li>29% primary refractory disease</li>
                    <li>37% received prior autologous HSCT</li>
                    <li>5% received prior BV</li>
                    <li>39% received prior radiation therapy</li>
                </ul>
            `} 
        />
    
        <StudyCopyBlock 
            footnotes={[
                {
                    label:'e.',
                    text: `Randomization was stratified by prior autologous HSCT (yes vs no) and disease status after frontline therapy (primary refractory vs relapse <12 months after completion vs relapse ≥12 months after completion)`
                }
            ]}
            definitions={`
                HSCT = hematopoietic stem cell transplantation; IV = intravenously. 
            `} 
        />
    </ComponentWrapper>
);

export default CHL;
