import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, PageSubSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, PrimaryClaim, KMCurve, SecondaryCallout, ComparisonTable, EndpointDisplay, TextBlock, ReferencesBlock } from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import CHL from '../../components/StudyDesign/__study-design/chl'
import { concrete } from '../../util/colors';
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-204 & KEYNOTE-087 - Clinical Trial Results | HCP`,
    keywords: `classic hodgkin lymphoma, keynote 204, keynote 087, classic hodgkin lymphoma clinical trial results`,
    description: `Health care professionals may find clinical trial results for KEYNOTE-204 and KEYNOTE-087 in patients with relapsed or refractory classical Hodgkin Lymphoma (R/R cHL).`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/classical-hodgkin-lymphoma/","@type":"MedicalTrial","name":"KEYNOTE⁠-⁠204","description":"Clinical Findings from KEYNOTE⁠-⁠204","trialDesign":"A Phase 3, Randomized, Open-Label, Active-Controlled Study of KEYTRUDA vs Brentuximab Vedotin"}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/classical-hodgkin-lymphoma/","@type":"MedicalTrial","name":"KEYNOTE⁠-⁠087","description":"Clinical Findings from KEYNOTE⁠-⁠087","trialDesign":"The efficacy of KEYTRUDA as a single agent was investigated in 210 patients with relapsed or refractory cHL enrolled in a multicenter, nonrandomized, open-label study."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/classical-hodgkin-lymphoma/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

//from /src/util/data/indications.js
const indicationId = 20;
const jobCode = jobCodes[20].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/classical-hodgkin-lymphoma/' },
                { text: 'Dosing', url: '/dosing/options/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    { label: 'Clinical Findings from KEYNOTE&#8288;-&#8288;204' }, 
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;204' }, 
    { label: 'Clinical Findings from KEYNOTE&#8288;-&#8288;087' }, 
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;087' }, 
]

const clinicalFindingsKMCurveReferences = [
    {
        label: "a.",
        text: "HR based on the stratified Cox proportional hazard model."
    },
    {
        label: "b.",
        text: "<i>P</i> value based on a stratified log-rank test. One-sided <i>P</i> value, with a prespecified boundary of 0.0043."
    }
]

const PFStableDefinitions = "HR = hazard ratio; CI = confidence interval.";

const PFStableFootnotes = [
    {
        label: "c.",
        text: "Based on Kaplan-Meier estimates."
    },
];

const DORtableDefinitions = "+ Denotes a censored value."

const DORtableFootnotes = [
    {
        label: "d.",
        text: "Based on Kaplan-Meier estimates."
    }
]

const key087ComparisonTableDefinitions = "Median follow-up time was 9.4 months."
const key087ComparisonTableFootnotes = [
    {
        label: "f.",
        text: "Based on patients (n=145) with a response by independent review."
    }
]

const pageReferences = [
    {
        label: "1.",
        text: "Kuruvilla J, Ramchandren R, Santoro A, et al. Pembrolizumab versus brentuximab vedotin in relapsed or refractory classical Hodgkin lymphoma (KEYNOTE&#8288;-&#8288;204): an interim analysis of a multicentre, randomised, open-label, phase 3 study. <i>Lancet Oncol.</i> 2021;22(4):512-524."
    },
    {
        label: "2.",
        text: "Chen R, Zinzani PL, Fanale MA, et al. Phase II study of the efficacy and safety of pembrolizumab for relapsed/refractory classic Hodgkin lymphoma. <i>J Clin Oncol.</i> 2017;35(19):2125-2132"
    },
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                    <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light">Superior progression-free survival (PFS) with KEYTRUDA vs Brentuximab Vedotin (BV) in adult patients with relapsed or refractory (R/R) cHL</PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}
                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">

                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}>
                            <KMCurve
                                title="Kaplan-Meier Estimates of PFS With KEYTRUDA vs Brentuximab Vedotin (BV) in KEYNOTE&#8288;-&#8288;204<sup>a,b</sup>"
                                hr="HR=0.65; 95% CI, 0.48–0.88; <i>P</i>=0.0027"
                                footnotes={clinicalFindingsKMCurveReferences}
                                image={() => <StaticImage src="../../assets/lym-204-km-v-1.png" placeholder="blurred" 
                                alt="Kaplan-Meier Estimates of PFS With KEYTRUDA® (pembrolizumab) vs Brentuximab Vedotin (BV) in Patients With Relapsed or Refractory Classical Hodgkin Lymphoma (R/R cHL) in KEYNOTE-204" />} 
                            />
                            <SecondaryCallout 
                                title="35% reduction in the risk of disease progression or death with KEYTRUDA vs BV" 
                                alt=""    
                            />
                            <ComparisonTable 
                                title="Progression-Free Survival (PFS)" 
                                footnotes={PFStableFootnotes}
                                definitions={PFStableDefinitions} 
                                data={
                                [
                                    [
                                        {
                                            legendTitle: 'KEYTRUDA (n=151)',
                                        },
                                        {
                                            legendTitle: 'Brentuximab Vedotin (n=153)',
                                        },

                                    ],
                                    [
                                        {
                                            hrCiP: 'HR=0.65; 95% CI, 0.48-0.88; <i>P</i>=0.0027',
                                            colspan: 2,
                                            centerText: true,
                                        },
                                    ],
                                    [
                                        {
                                            label: 'Number of Patients With an Event',
                                            dataPoint: '54%',
                                            dataSubPoint: '(n=81/151)'
                                        },
                                        {
                                            label: 'Number of Patients With an Event',
                                            dataPoint: '58%',
                                            dataSubPoint: '(n=88/153)'
                                        }
                                    ],
                                    [
                                        {
                                            label: 'Median PFS<sup>c</sup>',
                                            dataPoint: '13.2 Months',
                                            dataSubPoint: '(95% CI, 10.9-19.4)'
                                        },
                                        {
                                            label: 'Median PFS<sup>c</sup>',
                                            dataPoint: '8.3 Months',
                                            dataSubPoint: '(95% CI, 5.7-8.8)'
                                        }
                                    ],

                                ]
                            }/>

                            <EndpointDisplay title="Objective Response Rate (ORR)" limitation="In 151 patients with KEYTRUDA" cards={
                                [
                                    {
                                        dataType: 'ORR',
                                        label: 'KEYTRUDA',
                                        rate: '66%',
                                        ci: '95% CI, 57–73',
                                        completeResponseRate: '25%',
                                        partialResponseRate: '41%',
                                    }
                                ]
                            }
                            />

                            <EndpointDisplay
                                title="Objective Response Rate (ORR)"
                                limitation="In 153 patients with Brentuximab Vedotin"
                                cards={
                                    [
                                        {
                                            legendSwatchColor: concrete,
                                            dataType: 'ORR',
                                            label: 'Brentuximab Vedotin',
                                            rate: '54%',
                                            ci: '95% CI, 46–62',
                                            completeResponseRate: '24%',
                                            partialResponseRate: '30%',
                                        }
                                    ]
                                }
                                additionalInformation={[
                                    {
                                        title: 'Additional Information',
                                        text: '<p>Difference in ORR is not statistically significant.</p>'
                                    }
                                ]}
                            />

                            <ComparisonTable title="Duration of Response (DOR) " definitions={DORtableDefinitions} footnotes={DORtableFootnotes} data={
                                [
                                    [
                                        {
                                            legendTitle: 'KEYTRUDA',
                                        },
                                        {
                                            legendTitle: 'Brentuximab Vedotin',
                                        },

                                    ],
                                    [
                                        {
                                            label: 'Median DOR<sup>d</sup>',
                                            dataPoint: '20.7 Months',
                                            dataSubPoint: '(range: 0.0+ to 33.2+ months)'
                                        },
                                        {
                                            label: 'Median DOR<sup>d</sup>',
                                            dataPoint: '13.8 Months',
                                            dataSubPoint: '(range: 0.0+ to 33.9+ months)'
                                        }
                                    ],

                                ]
                            } />

                            <SecondaryCallout 
                                title="Pediatric Use" 
                                text="Use of KEYTRUDA in pediatric patients is supported by evidence from adequate and well-controlled studies in adults with additional pharmacokinetic and safety data in pediatric patients." 
                                alt=""
                            />
                            <PageSubSection title={anchorLinkModelData[1].label}>
                                <CHL />
                            </PageSubSection>
                        </PageSection>

                        <PageSection title={anchorLinkModelData[2].label}>
                            <EndpointDisplay title="Objective Response Rate (ORR)" limitation="In 210 adult patients with R/R cHL" cards={
                                [
                                    {
                                        legendSwatchColor: concrete,
                                        dataType: 'ORR',
                                        label: 'KEYTRUDA',
                                        rate: '69%',
                                        ci: '95% CI, 62–75',
                                        completeResponseRate: '22%',
                                        partialResponseRate: '47%',
                                    }
                                ]
                            } />

                            <ComparisonTable title="Duration of Response (DOR) " definitions={key087ComparisonTableDefinitions} footnotes={key087ComparisonTableFootnotes} data={
                                [
                                    [
                                        {
                                            legendTitle: 'KEYTRUDA',
                                        }

                                    ],
                                    [
                                        {
                                            label: 'Median DOR<sup>f</sup>',
                                            dataPoint: '11.1 months',
                                            dataSubPoint: '(range: 0.0+ to 11.1 months)'
                                        }
                                    ],

                                ]
                            } />

                            <PageSubSection title={anchorLinkModelData[3].label}>
                                <TextBlock definitions="GVHD = graft-versus-host disease.">
                                    <h4>Phase 2, Multicenter, nonrandomized, open-label study<sup>2</sup></h4>
                                    <p>The efficacy of KEYTRUDA as a single agent was investigated in 210 patients with relapsed or refractory cHL enrolled in a multicenter, nonrandomized, open-label study.</p>
                                    <ul>
                                        <li>Patients with active, noninfectious pneumonitis, an allogeneic HSCT within the past 5 years (or &gt;5 years but with symptoms of GVHD), active autoimmune disease, a medical condition that required immunosuppression, or an active infection requiring systemic therapy were ineligible for the trial.</li>
                                        <li>Patients received KEYTRUDA at a dose of 200&nbsp;mg intravenously Q3W until unacceptable toxicity or documented disease progression, or for up to 24 months in patients who did not progress. Disease assessment was performed every 12 weeks.</li>
                                        <li>The major efficacy outcome measures (ORR, complete response rate, and DOR) were assessed by BICR according to the 2007 revised International Working Group (IWG) criteria.</li>
                                    </ul>
                                </TextBlock>
                            </PageSubSection>
                        </PageSection>

                    </TemplateColumn>

                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
